export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Acessos",
				root: true,
				icon: "fas fa-users",
				submenu: [
					{
						title: "Usuários",
						page: "users",
						bullet: "dot"
					},
					{
						title: "Grupos de acesso",
						page: "groups",
						bullet: "dot"
					},
				]
			},
			{
				title: "Anunciantes",
				root: true,
				icon: "fas fa-user",
				bullet: "dot",
				page: "anunciante"
			},
			{
				title: "Campanhas",
				root: true,
				icon: "fas fa-chart-bar",
				bullet: "dot",
				page: "campaign"
			},
			{
				title: "Pixel campanhas",
				root: true,
				icon: "fas fa-hand-pointer",
				bullet: "dot",
				page: "campaign-pixel"
			},
			{
				title: "Relatórios",
				root: true,
				icon: "fas fa-file-alt",
				submenu: [
					{
						title: "Dashboard",
						bullet: "dot",
						page: "report/report01"
					},
					{
						title: "Inventário de campanha",
						bullet: "dot",
						page: "report/report02"
					},
					{
						title: "Perfil de público",
						bullet: "dot",
						page: "report/report03"
					},
				]
			},
			{
				title: "Manutenção",
				root: true,
				icon: "fas fa-wrench",
				page: "maintenance",
				bullet: "dot"
			},
		]
	}
};
