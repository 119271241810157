import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const UserPage = lazy(() =>
	import("./user/UserPage")
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const AnunciantesPage = lazy(() =>
	import("./anunciante/AnunciantePage")
);

const CampaignPage = lazy(() =>
	import("./campaign/CampaignPage")
);

const CampaignPixelPage = lazy(() =>
	import("./campaign-pixel/CampaignPixelPage")
);

const ReportPage = lazy(() =>
	import("./report/ReportPage")
);

const MaintenancePage = lazy(() => 
	import("./maintenance/MaintenancePage")
);


export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/users" component={UserPage} />
				<Route path="/groups" component={GroupPage} />
				<Route path="/anunciante" component={AnunciantesPage} />
				<Route path="/campaign" component={CampaignPage} />
				<Route path="/campaign-pixel" component={CampaignPixelPage} />
				<Route path="/report" component={ReportPage} />
				<Route path="/maintenance" component={MaintenancePage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense >
	);
}
